svg.movob_1 {
  display: block;
  .pat-1 {
    transform-origin: 0% 33.905%;
    animation: movob_anim_clock_l 4.7s ease-in-out 3s infinite alternate both;
  }
  .pat-2 {
    transform-origin: 42.946% 37.333%;
    animation: movob_anim_flick_r 5s ease-in-out 1s infinite alternate both;
  }
  .pat-3 {
    transform-origin: 42.326% 46.095%;
    animation: movob_anim_rot_r 9.2s ease-in-out 2s infinite normal both;
  }
  .pat-4 {
    transform-origin: 77.054% 49.905%;
    animation: movob_anim_clock_l 4.6s ease-in-out 1s infinite alternate both;
  }
  .pat-5 {
    transform-origin: 63.101% 87.047%;
    animation: movob_anim_flick_r 4.7s ease-in-out 4s infinite alternate both;
  }
  // .cir-1 {
  //   animation: movob_anim_opacity 10s linear 0s infinite normal both;
  // }
}//svg.movob_1



@keyframes movob_anim_clock_l {// 秒針的動き, 左回り
  0% { rotate: 0deg; }
  20% { rotate: 0deg; }
  25% { rotate: -2deg;}
  40% { rotate: -2deg; }
  45% { rotate: -4deg; }
  60% { rotate: -4deg;}
  65% { rotate: -6deg; }
  80% { rotate: -6deg;}
  85% { rotate: -8deg; }
  100% { rotate: -8deg;}
}
@keyframes movob_anim_clock_r {// 秒針的動き, 右回り
  0% { rotate: 0deg; }
  20% { rotate: 0deg; }
  25% { rotate: 2deg;}
  40% { rotate: 2deg; }
  45% { rotate: 4deg; }
  60% { rotate: 4deg;}
  65% { rotate: 6deg; }
  80% { rotate: 6deg;}
  85% { rotate: 8deg; }
  100% { rotate: 8deg;}
}
@keyframes movob_anim_flick_r {// 振り子的動き, 右回り
  0% { rotate: 0deg; }
  50% { rotate: 0deg; }
  60% { rotate: 10deg;}
  100% { rotate: 10deg;}
}
@keyframes movob_anim_flick_l {// 振り子的動き, 左回り
  0% { rotate: 0deg; }
  50% { rotate: 0deg; }
  60% { rotate: -10deg;}
  100% { rotate: -10deg;}
}
@keyframes movob_anim_rot_r {// 1回転, 右回り
  0% { rotate: 0deg;}
  47% { rotate: 0deg;}
  50% { rotate: 360deg; }
  53% { rotate: 360deg; }
  100% { rotate: 360deg;}
}
@keyframes movob_anim_rot_l {// 1回転, 左回り
  0% { rotate: 0deg;}
  47% { rotate: 0deg;}
  50% { rotate: -360deg; }
  53% { rotate: -360deg; }
  100% { rotate: -360deg;}
}
@keyframes movob_anim_opacity {// 透明度 (cir用)
  0% { opacity: .75;}
  20% { opacity: .25;}
  50% { opacity: .90;}
  60% { opacity: .75;}
  100% { opacity: .75;}
}
@keyframes movob_anim_opacity_1 {// 透明度 (cir用)
  0% { opacity: .75;}
  40% { opacity: .75;}
  45% { opacity: .60;}
  60% { opacity: .60;}
  80% { opacity: .90;}
  95% { opacity: .90;}
  100% { opacity: .75;}
}



svg.movob_2 {
  display: block;
  .pat-1 {
    transform-origin: 23.077% -9.174%;
    animation: movob_anim_flick_r 5s ease-in-out 0.5s infinite alternate both;
  }
  .pat-2 {
    transform-origin: 0% 79.205%;
    animation: movob_anim_clock_r 4.6s ease-in-out 1.5s infinite alternate-reverse both;
  }
  .pat-3 {
    transform-origin: 99.734% 70.948%;
    animation: movob_anim_clock_l 4.8s ease-in-out 1s infinite alternate both;
  }
}//svg.movob_2



svg.movob_3 {
  display: block;
  .pat-1 {
    transform-origin: 68.966% 15.0%;
    animation: movob_anim_flick_r 5s ease-in-out 0s infinite alternate both;
  }
  .pat-2 {
    transform-origin: 46.059% 30.833%;
    animation: movob_anim_rot_l 12s ease-in-out 0s infinite alternate both;
  }
  .pat-3 {
    transform-origin: 61.576% 88.542%;
    animation: movob_anim_clock_l 4.8s ease-in-out 0.5s infinite alternate both;
  }
  .pat-4 {
    transform-origin: 81.034% 89.167%;
    animation: movob_anim_clock_r 4.8s ease-in-out 1s infinite alternate both;
  }
}//svg.movob_2



svg.movob_4 {// 272, 328
  display: block;
  .pat-1 {
    transform-origin: 0% 53.049%;//0, 174
    animation: movob_anim_clock_l 4.7s ease-in-out 3s infinite alternate both;
  }
  .pat-2 {
    transform-origin: 100.368% 59.146%;// 273, 194
    animation: movob_anim_flick_l 5s ease-in-out 1s infinite alternate both;
  }
}//svg.movob_4 (movob_1の左側)



svg.movob_5 {// 374 398
  display: block;
  .pat-3 {
    transform-origin: 0% 28.643%;// 0 114
    animation: movob_anim_rot_r 9.2s ease-in-out 2s infinite normal both;
  }
  .pat-4 {
    transform-origin: 59.626% 33.166%;// 223, 132
    animation: movob_anim_clock_l 4.6s ease-in-out 1s infinite alternate both;
  }
  .pat-5 {
    transform-origin: 35.829% 82.663%;// 134, 329
    animation: movob_anim_flick_r 4.7s ease-in-out 4s infinite alternate both;
  }
}//svg.movob_5 (movob_1の右側)
