/*========== page-footer ==========*/



.page__footer {
  background-color: #fff;
  color: $color-primary;
  border-top: 4px solid $color-primary;
  padding-top: 35px;
  padding-bottom: 73px;
  position: relative;
  z-index: 4;
  @include mq-up(md) {
    padding-top: 65px;
    padding-bottom: 45px;
  }



  nav {
    display: grid;
    font-family: $font-family-eng;
    font-weight: 300;
    @include font_fat_disable;
    gap: 29px 0;
    grid-template-areas: 'home home home' 'contact . pages';
    grid-template-columns: minmax(auto, 156px) minmax(22px, 60px) 1fr;
    grid-template-rows: 30px minmax(30px, 1fr);
    letter-spacing: 0.05em;
    line-height: 1.05;
    margin-bottom: 7px;
    padding-right: 10px;// 最大でも右にアキを作りたい
    @include mq-up(md) {
      gap: 0;
      grid-template-areas: 'contact . pages home .';
      grid-template-columns: 156px 60px 1fr 160px 20px;
      grid-template-rows: 30px;
      padding-right: 0;
    }
    @include mq-up(xxl) {
      grid-template-columns: 156px 60px 1fr 160px calc(50% - 640px + 20px);
    }

    .contact {
      grid-area: contact;
      a {
        align-items: center;
        background-color: $color-primary;
        color: #fff;
        display: flex;
        height: 100%;
        padding: 5px getEm(10px);
        width: 100%;
        @include mq-up(sm) {
          padding: 5px 0 5px getEm(23px);
        }
        @include mq-up(md) {
          transition: background-color 0.15s linear;
          &:hover {
            background-color: $color-primary-lite;
          }
        }
      }//-a
    }//.contact

    .pages {
      grid-area: pages;
      display: flex;
      a {
        align-items: center;
        color: $color-primary;
        display: flex;
        height: 100%;
        border: 1px solid $color-primary;
        padding: 5px getEm(12px);
        @include mq-up(sm) {
          padding: 5px getEm(22px);
        }
        @include mq-up(md) {
          transition-duration: 0.15s;
          transition-property: color, background-color;
          transition-timing-function: linear;
          &:hover {
            background-color: $color-primary-lite;
            color: #fff;
          }
        }
        + a { margin-left: -1px; }// 右端以外のborder-rightを隠すため
      }//-a
    }//.pages

    .home {
      grid-area: home;
      padding-left: 10px;
      @include mq-up(md) { padding-left: 0; }
      a {
        background: url(../img/logo-cliphopper.svg) 0 50% / contain no-repeat;
        display: block;
        width: 160px;
        height: 100%;
        @include swaptext;
      }//-a
    }//.home

  }//-nav



  .colophon {
    font-size: 10px;
    padding-left: 10px;
    @include mq-up(md) {
      padding-left: 22px;
      a {
        transition: border-bottom-color 0.15s linear;
        border-bottom: 1px solid transparent;
        &:hover {
          border-bottom-color: $color-primary;
        }
      }//-a
    }//(md)
  }//.colophone


}//.page__footer
