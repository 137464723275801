@use 'sass:math';
// ====================
//
//  vars-mixins (variables & mixins)
//
// ====================


//====================
// variables
//====================




$font-family: "Sawarabi Gothic", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic", "游ゴシック", "YuGothic", "游ゴシック体", "Meiryo", "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif;

$font-family-eng: "Poppins", "Century Gothic", "Century Gothic Std", "Sawarabi Gothic", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic", "游ゴシック", "YuGothic", "游ゴシック体", "Meiryo", "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif;
// Poppins: 2 weights [light(300), Semibold(600)]

$font-size: 16px;
$font-size-sp: $font-size;
$line-height: 1.50;

$color-primary: #0086cd;// home背景, アクセントなど
$color-primary-lite: #68c2e7;// header文字色など
$color-primary-dark: #00649a;// recent works link背景色など
$color-text: #606060;  // グレイ文字色
$color-link: $color-primary;  // リンク文字色
$color-bg: #fff;// ページ背景色
$color-story: #faf3ea;// home story sect background-color

// md+でのデザインサイズ計算用
$md_ratio: calc(768 / 1240);
$lg_ratio: calc(992 / 1240);
$xl_ratio: calc(1200 / 1240);



//====================
// mixins
//====================



//===== unique mixins =====//

// mainv共通: height 900pxデザインでのpx値を渡すと vhとsvhの値を返す.
// (単位なしで処理するので, 呼び出し側で` * 1svh;`などと単位づけをすること)
@function get_vh_for_home_mv($num, $baseNum:900) {
  $percent: math.div($num, $baseNum) * 100;// 百分率 (98.76543210... とか)
  @return math.div( round($percent * 100000), 100000);// 小数点以下5位で四捨五入したい (98.76543 とか)// 3位くらいにしたかったけど基準px値が整数にならんかった...
}

// mainv共通: pc版vw計算関数 ...width 1520pxデザインでのpx値を渡すと vw (svw) の値を返す.
@function get_vw_for_home_mv_pc($num, $baseNum:1520) {
  $percent: math.div($num, $baseNum) * 100;// 百分率 (98.76543210... とか)
  @return math.div( round($percent * 100000), 100000);// 小数点以下5位で四捨五入したい (98.76543 とか)// 3位くらいにしたかったけど基準px値が整数にならんかった...
}
// mainv共通: sp版vw計算関数 ...width 586pxデザインでのpx値を渡すと vw (svw) の値を返す.
@function get_vw_for_home_mv_sp($num, $baseNum:586) {
  $percent: math.div($num, $baseNum) * 100;// 百分率 (98.76543210... とか)
  @return math.div( round($percent * 100000), 100000);// 小数点以下5位で四捨五入したい (98.76543 とか)// 3位くらいにしたかったけど基準px値が整数にならんかった...
}




// w586デザイン(sp用)からvwを返す. 主にfont-sizeに利用するつもり.
@function get_vw_for_w586($num) {
  $percent: math.div($num, 586) * 100;
  @return math.div( round($percent * 100000), 100000) * 1vw;// vwで返す
}
// w1280+デザイン(pc用)からvwを返す. xxl未満に利用する可能性がある.
@function get_vw_for_w1280($num) {
  $percent: math.div($num, 1280) * 100;
  @return math.div( round($percent * 100000), 100000) * 1vw;// vwで返す
}



//===== common mixins =====//

@function getRem($px, $basepx:$font-size) {
  @return math.div($px, $basepx) * 1.0rem;
}
@function getEm($px, $basepx:$font-size) {
  @return math.div($px, $basepx) * 1.0em;
}

@mixin swaptext() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin border-radius($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($xdir, $ydir, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $xdir $ydir $blur $color;
       -moz-box-shadow: inset $xdir $ydir $blur $color;
            box-shadow: inset $xdir $ydir $blur $color;
  } @else {
    -webkit-box-shadow: $xdir $ydir $blur $color;
       -moz-box-shadow: $xdir $ydir $blur $color;
            box-shadow: $xdir $ydir $blur $color;
  }
}

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing:    $type;
  box-sizing:         $type;
}

@mixin clearfix() {
  &:after {
    content: "";
    clear: both;
    height: 0;
    display: block;
    visibility: hidden;
  }
}

@mixin gradient ($startColor: #eee, $endColor: white) {
  background-color: $startColor;
  background: -webkit-linear-gradient(top, $startColor, $endColor);
  background: -moz-linear-gradient(top, $startColor, $endColor);
  background: -ms-linear-gradient(top, $startColor, $endColor);
  background: -o-linear-gradient(top, $startColor, $endColor);
  background: linear-gradient(to bottom, $startColor, $endColor);
}


@mixin transition($prop:all, $duration:0.15s, $easing:linear, $delay:0.0s) {
  -webkit-transition: $prop $duration $easing $delay;
  -moz-transition:    $prop $duration $easing $delay;
  -ms-transition:     $prop $duration $easing $delay;
  -o-transition:      $prop $duration $easing $delay;
  transition:         $prop $duration $easing $delay;
}
// transition: property duration timing-function delay|initial|inherit;
// duration と delay は必ず単位 s をつけること.
// デフォは all 0s ease 0s
// 順番は必ず守ること. デフォでいい場合は後ろから省略できる.
// 例えばdelayはゼロでいいなら前の3つだけ指定してもいい (opacity 1s linear) とか.
//
// easing のキーワード: ease、linear、ease-in、ease-out、 ease-in-out

@mixin transition-solo($prop:all) {
  $duration:0.15s; $easing:linear; $delay:0.0s;
  -webkit-transition: $prop $duration $easing $delay;
  -moz-transition:    $prop $duration $easing $delay;
  -ms-transition:     $prop $duration $easing $delay;
  -o-transition:      $prop $duration $easing $delay;
  transition:         $prop $duration $easing $delay;
}

@mixin font_fat_default(){
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  text-rendering: auto;// ただしまだstandardではない
}
@mixin font_fat_disable(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;// ただしまだstandardではない
}
// text-rendering は他にも値がある. 詳しくはMDN: https://developer.mozilla.org/en-US/docs/Web/CSS/text-rendering



//===== media query 関連 =====//

$grid-breakpoints: (
  xs: 320px,
  ssm: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1280px
) !default;// ATTENSION: XSサイズは従来は 0 だった. 今回は getSizeOf() で利用したいので 320px を設定してある.

$screen-ssm-min: map-get($grid-breakpoints, ssm); // 375px (iPhone 6 width)
$screen-sm-min: map-get($grid-breakpoints, sm); // 576px
$screen-md-min: map-get($grid-breakpoints, md); // 768px tablets
$screen-lg-min: map-get($grid-breakpoints, lg); // 992px desktop
$screen-xl-min: map-get($grid-breakpoints, xl); // 1200px desktop-large
$screen-xxl-min: map-get($grid-breakpoints, xxl); // 1280px design

// 指定px以上の幅 (min-width) のメディアクエリを記述するmixin
@mixin mq-up($breakpoint: md) {
  @media screen and (min-width: #{map-get($grid-breakpoints, $breakpoint)} ) {
    @content;
  }
}
// 使い方:
// @include mq-up(sm) {
//  ... (ここの記述内容が @content としてそのまま使われる)
// }

@mixin mq-down($breakpoint: md) {
  @media screen and (max-width: #{map-get($grid-breakpoints, $breakpoint) - 1px } ) {
    @content;
  }
}
