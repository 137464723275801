/*========== works.html - .works__sect ==========*/

.works__sect {
  position: relative;
  z-index: 2;

  .sect-body {
    display: flex;
    flex-wrap: wrap;
    gap: getRem(70px) getRem(42px);
    justify-content: center;
  }//.sect-body

  + .works__sect {
    margin-top: getRem(175px);
  }

}//.works__sect



// 各項目
.works__item {

  figure > img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 240px;
  }//figure > img

  .item-meta {
    margin: getEm(13px, 10px) 0 0;
    padding: 0;
    list-style: none;
    font-size: getRem(10px);
    color: $color-primary;
    letter-spacing: 0.04em;

    li {
      margin-top: getEm(8px, 10px);
    }
  }//.item-meta

}//.works__item



/*=== .page__main-foot (page__main底部リンクボタン) ===*/
.page__main-foot {
  margin-top: getRem(175px);//NOTE: とりあえず works__sect間marginと同じにした
}


/*===== svg =====*/
.page.works {

  .page__main {
    overflow: hidden;
    position: relative;
    height: 100%;
  }//.page__main

  .movob_4, .movob_5 {
    position: absolute;

  }//.movob_4, .movob_5

  .movob_4 {
    //272 328
    width: 272px;
    height: 328px;
    top: 47%;
    left: -9.25vw;
    @include mq-up(xxl) {
      left: 50%;
      transform: translateX(-758px);
    }
  }//.movob_4

  .movob_5 {
    //374 398
    width: 374px;
    height: 398px;
    top: 53%;
    right: -16vw;
    @include mq-up(xxl) {
      right: 50%;
      transform: translateX(846px);
    }
  }//.movob_5

}//.page.works
