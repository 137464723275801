/*========== contact.html: cont__sect ==========*/

.cont__sect {
  line-height: calc(25 / 16);
  margin-bottom: (260px - 120);// mainのpaddingぶん差し引き

  form {
    font-size: getRem(18px);
    margin-top: getRem(50px);

    .form-row {
      display: flex;
      flex-direction: column;

      @include mq-up(sm) {
        align-items: flex-start;
        flex-direction: row;
      }

      + .form-row {
        margin-top: getEm(24px, 18px);
        @include mq-up(sm) { margin-top: getEm(10px, 18px); }
      }

      > label {
        @include font_fat_disable;
        margin-bottom: 0.35em;

        @include mq-up(sm) {
          align-items: center;
          display: flex;
          flex: 0 0 getEm(210px, 18px);
          margin-bottom: 0;
          padding-top: getEm(16px, 18px);
        }//(sm)
        @include mq-up(md) { flex-basis: getEm(310px, 18px); }

        &.required::after {
          align-items: center;
          background-color: #dc1617;
          border-radius: getEm(3px, 10px);
          color: #fff;
          content: '必須';
          font-size: getEm(10px, 18px);
          // font-weight: bold;//...なぜか"須"だけが変に太くなる
          @include font_fat_default;
          letter-spacing: 0.08em;
          line-height: calc(18 / 10);
          margin-left: 0.75em;
          padding: 0 getEm(3px, 10px);
        }//&.required::after
      }//> label

      input[type=text], input[type=email], textarea {
        border: none;
        padding: 1em;
        width: 100%;
      }//input[type=text], input[type=email], textarea

      //=== お問い合わせ種別のcheckboxリスト
      ul.checkboxes {
        @include font_fat_disable;
        list-style: none;
        margin: 0;
        padding: 0 getEm(10px, 18px);
        @include mq-up(sm) { padding: 0; }
      }//ul.checkboxes

      input[type=checkbox] {
        margin-right: 0.25em;
      }//input[type=checkbox]

      //=== 個人情報〜
      &.single-checkbox {
        > label.required {
          flex-basis: auto;
          &::after { margin-left: 0; }
        }
        a { text-decoration: underline; }
      }//&.single-checkbox

      //=== CONFIRM
      &.confirm {
        margin-top: getRem(64px);
        display: block;

        > input {
          background-color: $color-primary;
          border: none;
          color: #fff;
          display: block;
          font-family: $font-family-eng;
          font-size: getRem(24px);
          @include font_fat_disable;
          line-height: calc(50 / 24);
          margin: 0 auto;
          width: getEm(240px, 24px);
        }//> input

      }//&.confirm

    }//.form-row

  }//form

}//.cont__sect
