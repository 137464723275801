/*========== home-direc ==========*/

.page.home .direc__sect {
  // background-color: #fff;
  color: $color-primary;
  // clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 5.263vw);
  // overflow: hidden;
  position: relative;

  svg.movob_1 {
    position: absolute;
    height: auto;
    left: 50%;
    top: -21.5vw;
    translate: -30% 0;
    width: 110vw;// 実測値
    @include mq-up(md) {
      left: calc(50% + 17.566vw);// w1520で267pxだから
      top: -11%;
      translate: 0;
      width: 645px;
    }
    @include mq-up(xxl) { left: calc(50% + 267px); }
  }//svg.movob_1

  svg.movob_2 {
    position: absolute;
    display: none;
    @include mq-up(md) {
      display: block;
      right: calc(50% + 25.329vw);// w1520で385pxだから
      top: 24.342vw;// w1520で370pxだから (%でやると狭いviewほど下に表示されちゃってダメだな)
    }
    @include mq-up(xxl) {
      right: calc(50% + 385px);
      top: 370px;
    }
  }//svg.movob_2

  .container {
    position: relative;
    padding-top: 20.819vw;
    @include mq-down(md) { width: 100%; }
    @include mq-up(md) { padding-top: 145px; }
  }

  .sect-head {
    padding-bottom: 62px;

    @include mq-up(md) {
      align-items: center;
      display: flex;
      padding-bottom: 160px;
    }

    picture {
      @include mq-up(md) {
        flex: 0 0 50%;
      }
      > img {
        height: auto;
        width: 100%;
      }//-> img
    }//-picture

    h2 {
      background-color: #fff;
      font-size: getRem(20px);
      font-weight: normal;
      letter-spacing: 0.03em;
      line-height: calc(29 / 20);//1.45
      padding: getEm(24px, 20px) 10px;
      @include font_fat_disable;

      @include mq-up(md) {
        font-size: getRem(22px);
        line-height: calc(36 / 22);//1.63636364
        margin-left: getEm(-40px, 22px);
        padding-block: getEm(30px, 22px);
        padding-left: getEm(40px, 22px);
      }
    }//h2

  }//.sect-head

  .sect-body {
    padding: 0 10px;
    @include mq-up(md) { padding: 0 0 0 25%; }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      @include font_fat_disable;
    }//ul

    li {
      align-items: start;
      display: flex;
      gap: getEm(10px);
      position: relative;

      + li {
        margin-top: getEm(20px);
      }

      &:before {
        background-color: $color-primary-lite;
        border-radius: 50%;
        content: '';
        display: block;
        flex: 0 0 1em;
        font-size: getEm(20px);
        height: 1em;
        position: relative;
        top: 0.2em;
        width: 1em;
      }
    }//-li

  }//.sect-body


}//.page.home .direc__sect
