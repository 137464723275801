/*========== faq: price__sect ==========*/

.price__sect {
  margin-top: getRem(66px);
  margin-bottom: getRem(60px);

}//.price__sect



//=== 各ポスト
.price__post {
  border-left-width: getEm(20px, 14px);
  border-left-style: solid;
  font-size: getRem(14px);
  @include font_fat_disable;
  line-height: calc(26 / 14);
  margin-top: getEm(8px, 14px);
  padding: getEm(30px, 14px) getEm(20px, 14px);
  @include mq-up(lg) {
    padding-inline: getEm(60px, 14px);
    margin-left: auto;
    margin-right: 0;
    max-width: 930px;// これを超えたら左側にマージンが発生するのだ
  }

  h3 {
    font-size: getEm(20px, 14px);
    @include font_fat_default;
    font-weight: normal;
    line-height: calc(27 / 20);
    margin-bottom: getEm(17px, 20px);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  table {
    border-width: 0;
    border-collapse: collapse;
    line-height: calc(24 / 14);
    margin: getEm(8px, 14px) 0;
    th {
      font-weight: normal;
      text-align: left;
      width: 16em;
      padding-right: 0.25em;
    }
    td { padding-left: 0.25em; }
    th, td { vertical-align: top; }
  }//table

  a { border-bottom: 1px solid $color-link; }

  // カラーリング設定
  &.thick {
    border-left-color: #0086cd99;// (opacity 0.6; デザインでは0.5だけど0.1の上に乗ってるから)
    background-color: #0086cd1a;// (opacity 0.1)
  }

  &.pale {
    border-left-color: #62cecc99;// (opacity 0.6; デザインでは0.5だけど0.1の上に乗ってるから)
    background-color: #62cecc1a;// (opacity 0.1)
  }
}//.price__post
