/*========== policy.html: poli__sect ==========*/

.poli__sect {
  color: #46505a;
  @include font_fat_disable;
  margin-bottom: getRem(296px - 120);// mainのpadding-bottomを差し引き

  p.desc {
    margin-bottom: getRem(66px);
    line-height: calc(25 / 16);
  }//p.desc

  dl {
    margin: 0 0 getRem(16px);
  }//dl

  dt {
    align-items: flex-start;
    gap: 1em;
    display: flex;
    font-size: getRem(20px);
    line-height: calc(30 / 20);
    letter-spacing: 0.02em;

    span.num {
      flex: 0 0 getEm(30px, 20px);
      background-color: $color-primary;
      color: #fff;
      display: inline-block;
      font-family: $font-family-eng;
      font-weight: bold;
      text-align: center;
    }//span.num

  }//dt

  dd {
    font-size: getRem(14px);
    line-height: calc(30 / 14);
    margin: getEm(24px, 14px) 0 0 getEm(30px, 14px);
  }//dd

}//.poli__sect
