/*========== faq: faqs__sect ==========*/

$faqs-1-color: #27D5E2;
$faqs-2-color: #44DCFC;
$faqs-3-color: #50C8F9;
$faqs-4-color: #39B1F0;



// .faqs__sect {
// }//.faqs__sect



//=== ページ内アンカーナビ ===//

.faqs__post-links {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  margin-bottom: getRem(110px);
  @include mq-up(md) {
    display: block;
    margin-bottom: getRem(100px);
  }

  .container {
    @include mq-up(md) {
      display: grid;
      gap: getEm(26px, 16px);
      grid-template-columns: repeat(2, 0.5fr);
      grid-template-rows: auto;
    }
  }//.container

  a {
    // align-items: center;
    display: flex;

    @include mq-up(md) {
      transition: color 0.15s linear;
      &:hover { color: $color-primary-lite; }
    }//(md)

    +a {
      margin-top: getEm(26px, 16px);
      @include mq-up(md) { margin-top: 0; }
    }//+a

    &:before {
      content: '';
      height: getEm(24px, 16px);
      width: getEm(24px, 16px);
      border-radius: 50%;
      flex: 0 0 getEm(24px, 16px);
      margin-right: getEm(12px, 16px);
    }//&:before

    &:nth-of-type(1)::before { background-color: $faqs-1-color; }
    &:nth-of-type(2)::before { background-color: $faqs-2-color; }
    &:nth-of-type(3)::before { background-color: $faqs-3-color; }
    &:nth-of-type(4)::before { background-color: $faqs-4-color; }

    &:nth-of-type(odd) {
      @include mq-up(md) {
        justify-self: end;
        &::before {
          order: 2;
          margin-right: 0;
          margin-left: getEm(12px, 16px);
        }//&::before
      }//(md)
    }//(odd)
    &:nth-of-type(even){
      @include mq-up(md) { justify-self: start; }
    }

  }//a


}//.faqs__post-links



//=== 各ポスト ===//

.faqs__post {
  color: #3C3C3C;// ページごとにテキストカラーが違うんだろうか...
  @include font_fat_disable;
  margin-top: getRem(60px + 30);

  @include mq-up(sm) { margin-top: getRem(60px); }
  @include mq-up(md) { margin-top: getRem(90px); }

  .container {
    display: grid;
    gap: 5vw;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);// 上下半分ずつは分量的に難しい
    height: 100%;
    min-height: get_vw_for_w586(530);
    position: relative;
    width: 100%;
    @include mq-up(sm) {
      gap: 0;
      grid-template-rows: repeat(2, 50%);// 上下に半分にします. 下半分がpost-body.
    }
    @include mq-up(md) {
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: 1fr;
      min-height: 410px;
    }
  }//.container

  //=== 背景の白丸
  .post-circle {
    background-color: #fff;
    border-radius: 50%;
    height: get_vw_for_w586(400);
    width: get_vw_for_w586(400);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: height 0.35s cubic-bezier(0.3, 0.2, 0.8, 2.0), width 0.35s cubic-bezier(0.3, 0.2, 0.8, 2.0);
    @include mq-up(md) {
      height: 320px;
      width: 320px;
    }
  }//.post-circle

  //=== さしえ
  picture {
    justify-self: center;// 左右の中央
    position: relative;
    @include mq-up(md) {
      align-self: center;// 上下の中央
    }
    > img {
      height: auto;
      // widthは項目ごとに設定します.
      scale: 1.0;
      transition: scale 0.35s cubic-bezier(0.3, 0.2, 0.8, 2.0) 0.1s;
    }
  }//picture

  //=== 文字内容
  .post-body {
    padding-left: getRem(95px);
    position: relative;
    @include mq-up(md) {
      // align-self: center;// 上下の中央
      padding-left: 0;
      padding-top: 135px;
    }
    @include mq-up(xxl) { padding-top: 135px; }

    h2 {
      // align-items: center;
      display: flex;
      font-size: getRem(24px);
      font-weight: normal;
      gap: getEm(15px, 24px);
      line-height: calc(34 / 24);
      margin-left: getEm(-37px, 24px);
      margin-bottom: getEm(33px, 24px);
      &::before {
        border-radius: 50%;
        content: '';
        flex: 0 0 getEm(22px, 24px);
        height: getEm(22px, 24px);
        width: getEm(22px, 24px);
        margin-top: getEm(8px, 24px);
      }
    }//h2

    p {
      font-size: getRem(14px);
      line-height: calc(26 / 14);
    }

  }//.post-body

}//.faqs__post



//=== focus時 (vh 25-75 の範囲にあるとき) ===//

.faqs__post.focus {
  // 白丸を大きく
  .post-circle {
    height: get_vw_for_w586(500);
    width: get_vw_for_w586(500);
    @include mq-up(md) {
      height: 400px;
      width: 400px;
    }
  }//.post-circle

  // 画像も大きく
  picture > img {
    scale: 1.2;
    @include mq-up(sm) { scale: 1.1; }
  }

}//.faqs__post.focus ...focus時



//=== ポストごとの設定
.faqs__post {

  &#part {
    picture > img {
      width: get_vw_for_w586(320);
      @include mq-up(md) { width: get_vw_for_w1280(430); }
      @include mq-up(xxl) { width: 430px; }
    }
    .post-body h2::before { background-color: $faqs-1-color; }
  }

  &#self {
    picture > img {
      width: get_vw_for_w586(340);
      margin-top: -8vw;
      @include mq-up(md) { width: get_vw_for_w1280(456); }
      @include mq-up(xxl) { width: 456px; }
    }
    .post-body h2::before { background-color: $faqs-2-color; }
  }

  &#seo {
    picture > img {
      width: get_vw_for_w586(292);
      margin-top: -6vw;
      @include mq-up(md) { width: get_vw_for_w1280(392); }
      @include mq-up(xxl) { width: 392px; }
    }
    .post-body h2::before { background-color: $faqs-3-color; }
  }

  &#cost {
    picture > img {
      width: get_vw_for_w586(335);
      @include mq-up(md) { width: get_vw_for_w1280(450); }
      @include mq-up(xxl) { width: 450px; }
    }
    .post-body h2::before { background-color: $faqs-4-color; }
  }
}//.faqs__post ...ポストごとの設定
