/*========== home-staff ==========*/

// .page.home .page__main {
//   padding-bottom: 0;
// }



.page.home .staff__sect {
  background-color: #fff;
  color: $color-primary;
  display: flex;
  flex-direction: column;
  padding-top: getRem(120px);
  padding-bottom: getRem(140px);
  position: relative;
  width: 100%;
  overflow: hidden;

  @include mq-up(md) {
    display: block;
    padding-top: getRem(185px);
  }



  svg.movob_3 {
    left: getRem(-60px);
    order: 2;
    position: relative;
    @include mq-up(md) {
      position: absolute;
      top: 120px;
      left: auto;
      right: calc(75% + 97px);
    }
    @include mq-up(xxl) {
      right: calc((100% - 1280px) * 0.5 + 1280px * 0.75 + 97px);
    }
  }//svg.movob_3



  .container {
    order: 1;
    position: relative;
  }//.container



  .sect-head {
    padding-bottom: 59px;
    @include mq-up(md) {
      padding-left: calc(25% - 30px);
    }
    h2 {
      font-size: getRem(24px);
      font-weight: normal;
      line-height: calc(36 / 24);
    }
  }//.sect-head



  .sect-body {
    padding-bottom: getEm(15px);
    @include mq-up(md) {
      padding-left: 25%;
    }

    dl {
      margin: 0;
      @include font_fat_disable;
    }//-dl

    dt {
      align-items: center;
      display: flex;
      font-size: getRem(20px);
      line-height: calc(27 / 20);
      padding-bottom: getEm(7px, 20px);
      position: relative;
      @include mq-up(md) {
        margin-left: -30px;
      }
      &:before {
        background-color: $color-primary-lite;
        border-radius: 50%;
        content: '';
        height: 1em;
        margin-right: getEm(10px, 20px);
        width: 1em;
      }//&:before
    }//-dt

    dd {
      margin-left: 0;
      p { margin: getEm(8px) 0; }
    }//-dd

    ul.links {
      display: flex;
      list-style: none;
      margin: getEm(8px) 0;
      padding: 0;
      gap: getEm(15px);
      a {
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: getEm(28px);
        width: getEm(28px);
        @include swaptext;
      }
      .faceb > a { background-image: url(../img/logo-faceb.svg); }
      .insta > a { background-image: url(../img/logo-insta.svg); }
    }//ul.links

  }//.sect-body








}//.page.home .staff__sect
