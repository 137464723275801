/*========== flow-cflow (制作フロー) ==========*/



.cflow__sect {
  background-color: #fff;
  margin-bottom: getRem(80px);
  padding-top: getRem(25px);
  @include mq-up(md) {
    margin-bottom: getRem(66px);
    padding-top: getRem(68px);
  }
}//.cflow__sect



//=== md未満で表示するチャート図.
.cflow__chart-graph {
  margin-bottom: getRem(58px);

  > svg {
    display: block;
    height: 644px;
    margin: 0 auto;
    width: auto;
    > g {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      &#chartline { transition-duration: 2.0s; }// #chartlineはゆっくり表示
      &.enter { opacity: 1; }
    }//>g
  }//> svg

}//.cflow__chart-graph (sp-only)



//=== ステップリスト. md+ではチャート図を左側に表示する.
.cflow__chart-steps {
  position: relative;
  width: 100%;

  @include mq-up(md) {
    height: 0;
    padding-top: calc(1419 / 1240 * 100%);
  }//(md)


  // stepsのコンテントブロック. 実際にstepを並べるのはここ.
  .cflow__chart-steps-content {
    position: relative;

    @include mq-up(md) {

      $row-empty-top: calc(44.8947 / 1419 * 100%);
      $row-item: calc(225.193 / 1419 * 100%);

      display: grid;
      grid-template-areas: '.' 'step1' 'step2' 'step3' 'step4' 'step5' 'step6';
      grid-template-columns: 100%;
      grid-template-rows: $row-empty-top repeat(6, $row-item);
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: calc( 1210 / 1240 * 100%);
    }//(md)
  }//.cflow__chart-steps-content


  // 背景画像chart-graph-lineをtransition表示するための要素.
  .step-bg {
    background: url(../img/flow/chart-graph-line@1x.svg) 0 0 / contain no-repeat;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: opacity 1.5s ease-in-out;
    @include mq-up(md) { display: block; }
  }//.step-bg

  // (.step-bg)のtransition後の設定. (.reveal) は cflowChart.js により付与される.
  .cflow__chart-steps-content.reveal .step-bg { @include mq-up(md) { opacity: 1; } }


  // (.step-item-番号)要素の共通設定
  [class*=step-item-] {
    @include mq-up(md) {
      display: grid;
      grid-template-columns: calc(280 / 1210 * 100%) calc(325 / 1210 * 100%) calc(605 / 1210 * 100%);
    }

    .label {
      font-family: $font-family-eng;
      font-size: getRem(40px);
      font-weight: 600;
      line-height: 1;
      margin-bottom: getEm(8px, 40px);
      @include mq-up(md) {
        background: url(../img/flow/chart-graph-diamond@1x.svg) 29% 0 / (calc(203.1395 / 280 * 100%) auto) no-repeat;
        padding-top: 29.5%;
        padding-right: 11%;
        text-align: center;
        font-size: 3.046875vw;
      }
      @include mq-up(xxl) { font-size: (40px - 1); }
    }//.label

    .heading {
      font-size: getRem(30px);
      font-weight: normal;
      @include font_fat_disable;
      // letter-spacing: 0.03em;
      letter-spacing: 0.05em;
      line-height: calc(41 / 30);
      margin-bottom: getEm(16px, 30px);
      @include mq-up(md) {
        align-items: center;
        background: url(../img/dothead-horizMono.svg) 0 50% / (19.692% auto) no-repeat;
        display: flex;
        font-size: 2.34375vw;
        padding-left: 24%;
      }
      @include mq-up(xxl) { font-size: 30px; }
    }//.heading

    .desc {
      font-size: getRem(18px);
      // letter-spacing: 0.03em;
      letter-spacing: 0.05em;
      @include font_fat_disable;
      @include mq-up(md) {
        font-size: 1.40625vw;
        line-height: 1.45;// デフォは1.5. STEP 03の高さが不足しそうなので, いちおうの設定.
        padding-top: 15.25%;
        overflow: hidden;
      }
      @include mq-up(xxl) { font-size: 18px; }
    }//.desc

    + [class*=step-item-] {
      margin-top: getRem(66px);
      @include mq-up(md) { margin-top: 0; }
    }//+ [class*=step-item-]

  }//[class*=step-item-]


  // (.step-item-番号)要素ごとの設定
  .step-item- {
    &1 { grid-area: step1; .label { color:#27D5E2; }}
    &2 { grid-area: step2; .label { color:#4AD9F6; }}
    &3 { grid-area: step3; .label { color:#4CD3FF; }}
    &4 { grid-area: step4; .label { color:#36C9FF; }}
    &5 { grid-area: step5; .label { color:#11B3F7; }}
    &6 { grid-area: step6; .label { color:#0295D9; }}

    &2, &4, &6 {
      .label {
        @include mq-up(md) {
          background-position: 59% 0;
          padding-right: 0;
          padding-left: 3%;
        }
      }//.label
    }//&2, &4, &6
  }//.step-item-


}//.cflow__chart-steps
