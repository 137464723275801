.page__header {
  padding: 0 15px 0 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  @include mq-up(md) {
    padding-right: 25px;
    padding-left: 30px;
  }



  > .container {
    display: grid;
    grid-template-areas: 'sitename navmenu';
    grid-template-columns: 140px 110px;
    justify-content: space-between;
    position: relative;
    width: 100%;
    @include mq-up(md) {
      grid-template-columns: 280px 110px;
    }
  }//>.container



  //=== SITENAME ===//

  .sitename {
    grid-area: sitename;
    height: 50px;
    padding-top: 25px;
    @include mq-up(md) {
      padding-top: 24px;
    }

    > a {
      background: url(../img/logo-cliphopper.svg) 0 50% / contain no-repeat;
      display: block;
      height: 20px;
      @include swaptext;
      @include mq-up(md) {
        height: 30px;
      }
    }//> a
  }//.sitename



  //=== NAV ===//

  nav {
    grid-area: navmenu;
    margin-top: -20px;
    position: relative;
    transition: margin-top 0.5s ease-out;
    padding: 25px 6px 0 0;
    height: 133px;
    background: url(../img/header-nav-lines.svg) 100% 0 / auto 130px no-repeat;
    cursor: pointer;

    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      line-height: 18px;
      font-size: 13px;
      font-family: $font-family-eng;
      font-weight: 300;
      text-align: right;

      a {
        color: $color-primary-lite;
        display: inline-block;
        margin-right: calc(11px * var(--p));
        opacity: 0;
        transition: opacity 0.25s ease-out calc(0.1s * var(--d)), color 0.15s linear 0s;

        @include mq-up(md) {
          &:hover { color: #fff; }
        }
      }//a

    }//ul

    &.active {
      margin-top: 0;
      >ul a { opacity: 1; }
    }//&.active

    &.darken > ul a {
      @include mq-up(md) {
        &:hover { color: $color-primary; }
      }
    }//&.darken > ul a

  }//nav



}//.page__header
