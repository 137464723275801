/*========== home-story (ストーリーテリング) ==========*/



.page.home .story__sect {
  background-color: $color-story;
  color: $color-primary;



  .container {
    @include mq-up(md) {
      display: grid;
      grid-template-areas: 'slide slide . main .';
      grid-template-columns: 20px 0.77fr 0.23fr 1.00fr 20px;
      grid-template-rows: auto;
    }
    @include mq-up(xxl) {
      grid-template-areas: 'slide slide slide . main . .';
      grid-template-columns: calc(50% - 640px) 20px 0.77fr 0.23fr 1.00fr 20px calc(50% - 640px);
      height: 872px;
    }
  }//.container



  //=== slide ===//

  #home-story-slide {
    height: 0;
    padding-top: calc(400 / 586 * 100%);
    position: relative;
    width: 100%;

    @include mq-up(md) {
      grid-area: slide;
      height: 872px;
      overflow: hidden;//FIXME: とりあえず
    }

    .slide {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: opacity 1.5s ease-in-out;

      @include mq-up(md) {
        height: 100%;
        width: 100%;
      }

      &.active {
        opacity: 1;
      }


      > img {
        // 586*400
        height: auto;
        width: 100%;

        @include mq-up(md) {
          // 640*872
          object-fit: cover;
          width: 100%;
          height: 100%;
          // aspect-ratio: 1240 / 1744;
        }
      }//> img
    }//.slide

  }//#home-story-slide



  //=== main ===//



  .story__main {
    padding: 58px 0 102px;

    @include mq-up(md) {
      grid-area: main;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
    }

    .sect-head {
      padding: 0 10px 69px;

      @include mq-up(md) {
        padding-inline: 0;
        // padding-top: 40px;
        // padding-bottom: 30px;
        padding-bottom: 40px;
      }
      @include mq-up(lg) {
        // padding-top: 50px;
        // padding-bottom: 40px;
        padding-bottom: 50px;
      }
      @include mq-up(xl) {
        padding-top: 100px;
        padding-bottom: 60px;
      }

      h2 {
        font-size: getRem(30px);
        font-weight: normal;
        line-height: calc(60 / 30);
        letter-spacing: 0.03em;
        @include font_fat_disable;
      }
    }//.sect-head

    .sect-body {
      padding: 0 10px;
      font-size: getRem(22px);
      line-height: calc(36 / 22);
      @include font_fat_disable;

      @include mq-up(md) {
        // padding: 0 0 40px;
        padding: 0 0 56px;

      }
      @include mq-up(lg) {
        // padding-bottom: 60px;
        padding-bottom: 76px;
      }
      @include mq-up(xl) {
        padding-bottom: 86px;
      }

    }//.sect-body

    .sect-foot {
      @include mq-up(md) {
        padding-top: 0;// ここではsect-bodyで調整させるため.
      }
    }//.sect-foot

  }//.story__main



}//.page.home .story__sect
