/*========== home-scopr (CLIPHOPPERで出来ること) ==========*/



.page.home .scope__sect {
  background-color: #fff;
  color: $color-primary;
  padding: getRem(120px) 0;



  .sect-head {
    padding-bottom: 18px;

    h2 {
      font-size: getRem(24px);
      line-height: calc(34 / 24);

      .logo {
        background: url(../img/logo-cliphopper.svg) 0 60% / contain no-repeat;
        display: inline-block;
        // filter: brightness(0) saturate(100%) invert(27%) sepia(98%) saturate(2338%) hue-rotate(182deg) brightness(100%) contrast(101%);
        height: getEm(34px, 24px);
        margin-right: getEm(17px, 24px);
        vertical-align: bottom;
        width: getEm(280px, 24px);
        @include swaptext;
      }//.logo

    }//-h2

  }//.sect-head



  .sect-body {

    ul {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(2, calc(50% - 5px));
      grid-template-rows: auto;
      list-style: none;
      margin: 0;
      padding: 0;
      @include font_fat_disable;

      @include mq-up(ssm) {
        gap: 16px;
        grid-template-columns: repeat(2, calc(50% - 8px));
      }
      @include mq-up(md) {
        gap: 20px;
        grid-template-columns: repeat(4, 1fr);
      }
      @include mq-up(lg) {
        gap: 20px 26px;
        // grid-template-columns: repeat(4, calc(50% - 13px))
      }
    }//-ul

    li {
      align-items: center;
      border: 1px solid $color-primary;
      border-radius: 10px;
      display: flex;
      height: 60px;
      justify-content: center;
      letter-spacing: 0.03em;
      padding: 0 0.50em;
      text-align: center;
      font-size: 0.80rem;
      @include mq-up(ssm) { font-size: 0.875rem; }
      @include mq-up(sm) { font-size: 1rem; }
      @include mq-up(md) { font-size: 0.90rem; }
      @include mq-up(lg) { font-size: 1rem; }
    }//-li

  }//.sect-body



}//.page.home .scope__sect
