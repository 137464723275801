/* basic-tags */

*, *:before, *:after { box-sizing: border-box; }

html, body {
  color: $color-text;
  font-family: $font-family;
  font-size: $font-size-sp;
  // height: 100%;
  line-height: $line-height;
  @include mq-up(md) { font-size: $font-size; }
}

html.smooth { scroll-behavior: smooth; }

body { position: relative; }

h1,h2,h3,h4,h5,h6,p,figure { margin: 0; }

h1,h2,h3,h4,h5,h6 { line-height: 1; }

a {
  color: $color-link;
  text-decoration: none;
  @include transition(color);
}

picture, picture > img { display: block; }



/* --------------------
  basic-classes
-------------------- */

.page__container {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.page__main {
  padding-bottom: getRem(140px);
  position: relative;
  @include mq-up(md) { padding-bottom: getRem(120px); }
}//.page__main



//=== 基本のコンテンツ幅
.ccw {// (common-content-width)
  margin: 0 auto;
  width: calc(100% - 20px);
  @include mq-up(md) { width: calc(100% - 40px); }
  @include mq-up(xxl) { width: 1240px; }
}

//=== [cmn] 他ページへのリッチ (グラデーション) リンクボタン
.cmn__grad-btn {
  align-items: center;
  border: 6px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #27d5e2 0%, #38d8ea 8%, #44daef 14%, #48dbf1 20%, #4ed4ff 40%, #33c8ff 60%, #1cbaf9 70%, #03aaf3 80%, #0083c4 100%);
  display: flex;
  font-size: getRem(24px);
  height: 90px;
  justify-content: flex-end;
  letter-spacing: 0.05em;
  line-height: calc(34 / 24);
  padding-right: 1em;
  width: 300px;
  @include font_fat_disable;

  @include mq-up(md) {
    align-items: start;
    border-top: none;
    border-right: none;
    border-left: none;
    height: 63px;
    transition: all 0.15s linear;
    &:hover {
      color: $color-primary-lite;
      color: #00649a;// XDから適当に拾ってきたやつ. 濃くなる方がいいかなーと思って...
      border-bottom-width: 12px;
    }//&:hover
  }//-(md)

  >.arrow {
    font-size: 0.5em;
    line-height: calc(34 / 12);
    margin-left: 1.25em;
  }
}//.cmn__grad-btn

//=== [cmn] タテに5つドットが並ぶ見出し (主にh2)
.cmn__dothead {
  background: url(../img/dothead.svg) 50% 0% / auto getEm(57px, 26px) no-repeat;
  color: $color-primary;
  font-size: getRem(26px);
  font-weight: normal;
  @include font_fat_disable;
  // letter-spacing: 0.03em;// これがデザイン通りなはずなんだけど足りない...
  letter-spacing: 0.05em;
  line-height: calc(36 / 26);
  margin-bottom: getEm(40px, 26px);
  padding-top: getEm(62px, 26px);
  text-align: center;
  @include mq-up(md) { margin-bottom: getRem(61px); }
}//.cmn__dothead

//=== svg (主にlogo) をcolor-primary色にするfilter
.colored-prime {
  // CSS filter generator:  https://codepen.io/sosuke/pen/Pjoqqp
  filter: brightness(0) saturate(100%) invert(27%) sepia(98%) saturate(2338%) hue-rotate(182deg) brightness(100%) contrast(101%);
}

//=== 通常ページのページタイトルセクション
body.generic.page .title__sect {
  color: #fff;
  height: 254px;
  margin-bottom: getRem(36px);
  position: relative;

  .container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
  }//.container

  .triangle {// 領域でマスクされる三角形をセクションの背景に使う.
    background-color: $color-primary;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    height: 100%;
    width: 7282px;
    translate: -50% 0;
    position: absolute;
    left: 50%;
    top: 0;
  }//.triangle

  h1 {
    font-size: getRem(30px);
    font-weight: normal;
    @include font_fat_disable;
    letter-spacing: 0.05em;// letter-spacing: 0.03em;
    line-height: calc(41 / 30);
    padding-top: getEm(106px, 30px);
    text-align: center;
    position: relative;

    span { display: block; }

    >.en {
      font-family: $font-family-eng;
      letter-spacing: normal;
      line-height: 1;
      margin-top: getEm(7px, 30px);
    }//>.en

  }//h1

}//body.generic.page .title__sect

//=== 下層ページ `.page__main` 最下段のリンク配置
.page__main-foot .cmn__grad-btn {
  margin: 0 0 0 auto;
}//.page__main-foot .cmn__grad-btn



/* --------------------
  page-level settings
-------------------- */

body.page.home {

  // 画像のプリロード
  &::after {
    // hide images...
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;

    // load images...
    content: url(../img/home/mv/street-s2@2x.png);
    @include mq-up(md) { content: url(../img/home/mv/street-l2@2x.png); }
  }//&::after



  .page__main {
    background-color: $color-primary;
    padding-bottom: 0;// 全体設定を打ち消し
  }//.page__main



  .page__main-bodies {
    background-color: #fff;
    overflow: hidden;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 5.263vw);
    position: relative;
  }//.page__main-bodies


  //=== .page.home 共有 .sect-foot ===//
  .sect-foot {
    padding: getRem(58px) 10px 0;
    display: flex;
    justify-content: flex-end;

    @include mq-up(md) { padding-inline: 0; }
  }//.sect-foot

}//body.page.home



body.page.faq,
body.page.contact,
body.page.policy {
  background-color: #faf3ea;
  .page__footer { background-color: #faf3ea; }
}







/* --------------------
  utility-classes
-------------------- */

.sr-only { display: none; }

.xs-only {
  @include mq-up(ssm) { display: none; }
}

.ssm-only {
  @include mq-down(ssm) { display: none; }
  @include mq-up(sm) { display: none; }
}

.xs-ssm {
  @include mq-up(sm) { display: none; }
}

.xs-sm, .sp-only {
  @include mq-up(md) { display: none; }
}


.sm-xxl, .sm-over {
  @media screen and (max-width: ($screen-sm-min - 1px) ) {
    display: none;
  }
}

.xs-md {
  @include mq-up(lg) { display: none; }
}

.pc-only {
  @media screen and (max-width: ($screen-md-min - 1px) ) {
    display: none;
  }
}

.no-bullet {
  list-style: none;
  padding: 0;
}

.no-margin {
  margin: 0;
}
